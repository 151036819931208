import React, { useState } from 'react';
import axios from 'axios';

const ResendVerificationEmail = () => {
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');

    const handleResend = async () => {
        try {
            const response = await axios.post('https://i-kiddy.com/public_html/index.php/api/resend-verify-email', { email });
            if (response.data.success) {
                setStatus('success');
                setMessage('Un nouveau lien de vérification a été envoyé à votre adresse email.');
            } else {
                setStatus('error');
                setMessage(response.data.message);
            }
        } catch (error) {
            setStatus('error');
            setMessage('Une erreur est survenue lors de l\'envoi du lien.');
        }
    };

    return (
        <div style={{ marginTop: '50px', textAlign: 'center' }}>
            <h1>Demander un nouveau lien de vérification</h1>
            <input
                type="email"
                placeholder="Votre email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ padding: '10px', width: '300px', marginBottom: '10px' }}
            />
            <br />
            <button
                onClick={handleResend}
                style={{
                    backgroundColor: '#ff2d20',
                    color: '#fff',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                }}
            >
                Envoyer
            </button>
            {status && <p style={{ color: status === 'success' ? 'green' : 'red' }}>{message}</p>}
        </div>
    );
};

export default ResendVerificationEmail;