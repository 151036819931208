import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ isAuthenticated, restricted, component: Component }) => {
    if (isAuthenticated && restricted) {
        return <Navigate to="/home" />; // Redirige vers une page privée si l'utilisateur est authentifié
    }
    return Component;
};

export default PublicRoute;