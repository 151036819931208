import React, { useState } from 'react';
import { 
    Avatar, 
    Button, 
    CssBaseline, 
    TextField, 
    FormControlLabel, 
    Checkbox, 
    Link, 
    Box, 
    Grid2,  
    Typography,
    Alert,
    IconButton, 
    InputAdornment,
    CircularProgress // Importation de CircularProgress
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from '../components/functions/Functions';
import Cookies from 'js-cookie'; // Assurez-vous d'avoir installé la bibliothèque js-cookie
import { constants } from '../auth/_constants/constants';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom'; // Remplace useHistory par useNavigate
import api from '../utils/api';

const theme = createTheme();

export default function Login({ setIsAuthenticated }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('error');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false); // État pour le chargement
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Utilise useNavigate à la place de useHistory

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleEmailChange = (event) => {
        const value = event.target.value;
        setEmail(value);
        setAlertMessage('');
        if (!validateEmail(value)) {
            setEmailError('Adresse e-mail invalide');
        } else {
            setEmailError('');
        }
    };
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setAlertMessage('');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (!validateEmail(email) || password.trim() === '') {
            setAlertMessage('Veuillez entrer des informations valides.');
            setAlertSeverity('error');
            return;
        }
    
        setLoading(true);
        try {
            const response = await api.post('login', {
                email: email,
                password: password,
            });

            if (!response.data.access_token || !response.data.refresh_token) {
                throw new Error('Invalid tokens');
            }
    
            dispatch({ type: constants.LOGIN_SUCCESS, payload: response.data });
            setAlertMessage(response.data.message);
            setAlertSeverity('success');
    
            localStorage.setItem('access_token', response.data.access_token);
            Cookies.set('refresh_token', response.data.refresh_token, { 
                expires: 15,
                secure: true, 
                sameSite: 'Strict',
            });
    
            localStorage.setItem('user', JSON.stringify(response.data.user));
            setIsAuthenticated(true)
            navigate('/home'); 
    
        } catch (error) {
            console.log('Error:', error.response?.data || error.message);
            setAlertMessage('Erreur lors de la connexion. Vérifiez vos informations.');
            setAlertSeverity('error');
        } finally {
            setLoading(false);
        }
    };

  
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid2 container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid2 
                    xs={12}
                    md={4}
                    elevation={6} 
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mx: 'auto',
                        maxWidth: '500px',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            p: 4,
                        }}
                    >
                        {alertMessage && (
                            <Alert variant="filled" severity={alertSeverity} sx={{ mb: 1, width: '100%' }}>
                                {alertMessage}
                            </Alert>
                        )}
                        <Avatar sx={{ m: 1, bgcolor: 'transparent', width: 56, height: 56 }}> {/* Ajustez la taille de l'avatar si nécessaire */}
                            <img 
                                src="https://www.i-kiddy.com/images/i-kiddy-logo.png" 
                                alt="Logo" 
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }} 
                            />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                size="small"
                                value={email}
                                onChange={handleEmailChange}
                                error={!!emailError}
                                helperText={emailError}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={showPassword ? 'text' : 'password'} 
                                id="password"
                                autoComplete="current-password"
                                size="small"
                                value={password}
                                onChange={handlePasswordChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, py: 1.2 }}
                                disabled={!email || !password || !!emailError || loading} // Désactiver le bouton pendant le chargement
                            >
                                {loading ? <CircularProgress size={24} /> : 'Sign In'} {/* Afficher l'indicateur de chargement */}
                            </Button>
                            <Grid2 container sx={{ justifyContent: 'space-between' }}>
                                <Grid2>
                                    <Link href="/forget-password" variant="body2" sx={{textDecoration:'none'}}>
                                        Forgot your password?
                                    </Link>
                                </Grid2>
                                <Grid2>
                                    <Link href="/register" variant="body2" sx={{textDecoration:'none'}}>
                                        {"Don't have an account? Sign up"}
                                    </Link>
                                </Grid2>
                            </Grid2>
                        </Box>
                        <Box sx={{ mt: 5, mb: 2 }}>
                            <Copyright />
                        </Box>
                    </Box>
                </Grid2>
            </Grid2>
        </ThemeProvider>
    );
}