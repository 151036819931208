import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ isAuthenticated, component: Component }) => {
    if (!isAuthenticated) {
        return <Navigate to="/login" />; // Redirige vers la page de connexion si non authentifié
    }
    return Component;
};

export default PrivateRoute;