import React from 'react';
import NavBar from './nav/NavBar';
import ContentSwitcher from './page/ContentSwitcher';

const ContenairGeneral = () => {
  return (
    <div>
      <NavBar />
      <div style={{ marginTop: '50px' }}>
        <ContentSwitcher />
      </div>
    </div>
  );
};

export default ContenairGeneral;