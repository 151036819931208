import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import rootReducer from './reduxReducer'; // Assurez-vous que le chemin est correct
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux'; // Importer le Provider

// Configurer le store avec le réducteur racine
const store = configureStore({
  reducer: rootReducer,
});

export { store }; // Exporter le store

// Créer la racine de l'application
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}> {/* Envelopper l'application avec le Provider */}
      <App />
    </Provider>
  </React.StrictMode>
);

// Si vous souhaitez commencer à mesurer les performances de votre application, passez une fonction
// pour enregistrer les résultats (par exemple : reportWebVitals(console.log))
// ou envoyez-les à un point de terminaison d'analyse. En savoir plus : https://bit.ly/CRA-vitals
// reportWebVitals();