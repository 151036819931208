import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PublicRoute from '../auth/PublicRoute';
import PrivateRoute from '../auth/PrivateRoute';
import Login from '../pages/Login';
import Register from '../pages/Register';
import ForgetPassword from '../pages/ForgetPassword';
import ContenairGeneral from '../pages/ContenairGeneral';
import VerifyEmail from '../pages/VerifyEmail';
import ResendVerificationEmail from '../pages/ResendVerificationEmail'; // Corrigez le chemin si nécessaire
import NotFound from '../pages/NotFound'; // Créez ce composant pour une meilleure gestion des erreurs 404
import NewPassword from '../pages/NewPassword';

const Routers = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        setIsAuthenticated(!!token);
    }, []);

    return (
        <Router>
            <Routes>
                {/* Routes publiques */}
                <Route
                    path="/"
                    element={
                        <PublicRoute
                            restricted={true}
                            isAuthenticated={isAuthenticated}
                            component={<Login setIsAuthenticated={setIsAuthenticated} />}
                        />
                    }
                />
                <Route
                    path="/login"
                    element={
                        <PublicRoute
                            restricted={true}
                            isAuthenticated={isAuthenticated}
                            component={<Login setIsAuthenticated={setIsAuthenticated} />}
                        />
                    }
                />
                <Route
                    path="/register"
                    element={
                        <PublicRoute
                            restricted={true}
                            isAuthenticated={isAuthenticated}
                            component={<Register />}
                        />
                    }
                />
                <Route
                    path="/forget-password"
                    element={
                        <PublicRoute
                            restricted={true}
                            isAuthenticated={isAuthenticated}
                            component={<ForgetPassword />}
                        />
                    }
                />
                <Route
                    path="/verifyEmail"
                    element={
                        <PublicRoute
                            restricted={true}
                            isAuthenticated={isAuthenticated}
                            component={<VerifyEmail />}
                        />
                    }
                />
                <Route
                    path="/newpassWord"
                    element={
                        <PublicRoute
                            restricted={true}
                            isAuthenticated={isAuthenticated}
                            component={<NewPassword />}
                        />
                    }
                />
                <Route
                    path="/resend-verification"
                    element={
                        <PublicRoute
                            restricted={true}
                            isAuthenticated={isAuthenticated}
                            component={<ResendVerificationEmail />}
                        />
                    }
                />

                {/* Route privée */}
                <Route
                    path="/home"
                    element={
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            component={<ContenairGeneral />}
                        />
                    }
                />

                {/* Route par défaut (404) */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
};

export default Routers;