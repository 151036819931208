import React from 'react';
import { useSearchParams } from 'react-router-dom';
import PrivatePage from '../PrivatePage';
import CreatePortalForm from './CreatePortalForm';

// Exemples de composants à afficher
const NotFoundComponent = () => <div>404 - Not Found</div>;

function ContentSwitcher() {
  const [searchParams] = useSearchParams(); // Récupère les paramètres de l'URL

  // Fonction pour récupérer la valeur du paramètre 'page'
  const getPageParam = () => {
    const page = searchParams.get('page'); // Récupère le paramètre 'page'
    return page || 'home'; // Si pas de paramètre, retourne 'home'
  };

  // Mapping des pages aux composants correspondants
  const renderComponent = () => {
    const page = getPageParam();

    switch (page) {
      case 'home':
        return <PrivatePage />;
      case 'createPortalForm':
        return <CreatePortalForm />;
      default:
        return <NotFoundComponent />;
    }
  };

  return (
    <div>
      {renderComponent()}
    </div>
  );
}

export default ContentSwitcher;