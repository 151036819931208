import React, { useState, useEffect } from 'react';
import {
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Box,
    Link,
    Grid2,
    Typography,
    Alert,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom'; // Hook pour récupérer les paramètres d'URL
import api from '../utils/api';

const theme = createTheme();

export default function NewPassword() {
    const [searchParams] = useSearchParams(); // Récupérer les paramètres d'URL
    const token = searchParams.get('token');
    const email = searchParams.get('email');

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('error');

    useEffect(() => {
        // Vérifie si le token ou l'email est manquant dans l'URL
        if (!token || !email) {
            setAlertMessage('Lien invalide ou incomplet.');
            setAlertSeverity('error');
        }
    }, [token, email]);

    // Fonction pour valider les critères du mot de passe
    const validatePassword = (password) => {
        const passwordCriteria = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}:"<>?])[A-Za-z\d!@#$%^&*()_+{}:"<>?]{8,}$/;
        return passwordCriteria.test(password);
    };

    const handlePasswordChange = (event) => {
        const value = event.target.value;
        setPassword(value);
        setAlertMessage('');

        if (!validatePassword(value)) {
            setPasswordError(
                'Le mot de passe doit comporter au moins 8 caractères, avec une lettre majuscule, une minuscule, un chiffre et un caractère spécial.'
            );
        } else {
            setPasswordError('');
        }
    };

    const handleConfirmPasswordChange = (event) => {
        const value = event.target.value;
        setConfirmPassword(value);
        setAlertMessage('');

        if (password !== value) {
            setConfirmPasswordError('Les mots de passe ne correspondent pas.');
        } else {
            setConfirmPasswordError('');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Vérifie si les paramètres d'URL sont valides avant d'envoyer la requête
        if (!token || !email) {
            setAlertMessage('Lien de réinitialisation invalide.');
            setAlertSeverity('error');
            return;
        }
    
        // Valide les mots de passe
        if (!validatePassword(password) || password !== confirmPassword) {
            setAlertMessage('Veuillez entrer un mot de passe valide et confirmer correctement.');
            setAlertSeverity('error');
            return;
        }
    
        const userData = {
            token,
            email,
            password,
            password_confirmation: confirmPassword, // Ajout du champ "password_confirmation"
        };
        
        try {
            // Requête pour réinitialiser le mot de passe
            const response = await api.post('reset-password', userData);
    
            if (response.data?.success) {
                setAlertMessage('Mot de passe réinitialisé avec succès ! Vous pouvez maintenant vous connecter.');
                setAlertSeverity('success');
            } else {
                setAlertMessage(response.data?.message || 'Une erreur est survenue.');
                setAlertSeverity('error');
            }
        } catch (error) {    
            setAlertMessage(
                error?.response?.data?.message ||
                'Erreur lors de la réinitialisation du mot de passe. Veuillez réessayer.'
            );
            setAlertSeverity('error');
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid2 container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid2
                    xs={12}
                    md={4}
                    elevation={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mx: 'auto',
                        maxWidth: '500px',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            p: 4,
                        }}
                    >
                        {alertMessage && (
                            <Alert variant="filled" severity={alertSeverity} sx={{ mb: 1, width: '100%' }}>
                                {alertMessage}
                            </Alert>
                        )}
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Réinitialiser votre mot de passe
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Nouveau mot de passe"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                size="small"
                                value={password}
                                onChange={handlePasswordChange}
                                error={!!passwordError}
                                helperText={passwordError}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Confirmer le mot de passe"
                                type="password"
                                id="confirm-password"
                                autoComplete="new-password"
                                size="small"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                error={!!confirmPasswordError}
                                helperText={confirmPasswordError}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, py: 1.2 }}
                                disabled={
                                    !!passwordError ||
                                    !!confirmPasswordError ||
                                    !password ||
                                    !confirmPassword ||
                                    !token ||
                                    !email
                                }
                            >
                                Réinitialiser le mot de passe
                            </Button>
                            <Grid2 container sx={{ justifyContent: 'space-between' }}>
                                <Grid2>
                                    <Link href="/login" variant="body2" sx={{ textDecoration: 'none' }}>
                                        LogIn
                                    </Link>
                                </Grid2>
                                <Grid2>
                                    <Link href="/register" variant="body2" sx={{ textDecoration: 'none' }}>
                                        {"Don't have an account? Sign up"}
                                    </Link>
                                </Grid2>
                            </Grid2>
                        </Box>
                        <Box sx={{ mt: 5, mb: 2 }}>
                            <Typography variant="body2" color="text.secondary" align="center">
                                {'Copyright © '}
                                <Link color="inherit" href="https://i-kiddy.com/">
                                    i-Kiddy
                                </Link>{' '}
                                {new Date().getFullYear()}.
                            </Typography>
                        </Box>
                    </Box>
                </Grid2>
            </Grid2>
        </ThemeProvider>
    );
}