import React, { useState } from 'react';
import { 
    Avatar, 
    Button, 
    CssBaseline, 
    TextField, 
    Link, 
    Box, 
    Grid2, 
    Typography, 
    Alert 
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from '../components/functions/Functions';
import api from '../utils/api';

const theme = createTheme();

export default function ForgetPassword() {
    const [email, setEmail] = useState('');
    const [formError, setFormError] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('error'); // Par défaut, l'alerte est une erreur

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            // const response = await api.post('forgot-password', { email });
            const response = await api.post('forgot-password', {
                email: email
            });

            setFormError(response?.data?.message || 'Lien de réinitialisation envoyé');
            setAlertSeverity('success'); // Définir l'alerte comme succès en cas de réussite
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data.message || 'Erreur lors de l\'envoi';
                setFormError(errorMessage);
            } else {
                setFormError('Une erreur est survenue. Veuillez réessayer.');
            }
            setAlertSeverity('error'); // Afficher l'erreur si la requête échoue
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid2 container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid2 
                    xs={12}
                    md={4}
                    elevation={6} 
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',  // Centrage vertical
                        alignItems: 'center',  // Centrage horizontal
                        mx: 'auto',  // Centrage horizontal complet
                        maxWidth: '500px',  // Limite la largeur maximale
                        width: '100%',  // Assure que la largeur soit adaptative
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            p: 4,
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'transparent', width: 56, height: 56 }}> {/* Ajustez la taille de l'avatar si nécessaire */}
                            <img 
                                src="https://www.i-kiddy.com/images/i-kiddy-logo.png" 
                                alt="Logo" 
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }} 
                            />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Mot de passe oublié
                        </Typography>

                        {formError && (
                            <Alert variant="filled" severity={alertSeverity} sx={{ width: '100%', mt: 2 }}>
                                {formError}
                            </Alert>
                        )}

                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                size="small"  // Taille réduite pour les champs
                                value={email}
                                onChange={(e) => setEmail(e.target.value)} // Mettre à jour l'email
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, py: 1.2 }}  // Réduction du padding vertical
                            >
                                Envoyer
                            </Button>
                            
                            <Grid2 container sx={{ justifyContent: 'space-between' }}>
                                <Grid2 >
                                    <Link href="/login" variant="body2" sx={{textDecoration:'none'}}>
                                        LogIn
                                    </Link>
                                </Grid2>
                                <Grid2 >
                                    <Link href="/register" variant="body2" sx={{textDecoration:'none'}}>
                                        {"Don't have an account? Sign up"}
                                    </Link>
                                </Grid2>
                            </Grid2>
                        </Box>
                        <Box sx={{ mt: 5, mb: 2 }}>
                            <Copyright />
                        </Box>
                    </Box>
                </Grid2>
            </Grid2>
        </ThemeProvider>
    );
}