import React, { useState } from 'react';
import {
    Avatar,
    Button,
    CssBaseline,
    TextField,
    FormControlLabel,
    Checkbox,
    Link,
    Box,
    Grid2,
    Typography,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    Alert,
    CircularProgress,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom'; // Pour la redirection
import api from '../utils/api';

const theme = createTheme();

export default function Register() {
    const navigate = useNavigate();
    const [birthDate, setBirthDate] = useState(null);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [gender, setGender] = useState('female');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [formError, setFormError] = useState('');
    const [termsError, setTermsError] = useState(false); // État pour l'erreur des termes
    const [alertSeverity, setAlertSeverity] = useState('error');
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handlePasswordChange = (event) => {
        const value = event.target.value;
        setPassword(value);

        const isValid =
            value.length >= 8 &&
            /[A-Z]/.test(value) &&
            /[0-9]/.test(value) &&
            /[!@#$%^&*(),.?":{}|<>]/.test(value);

        setPasswordError(
            isValid ? '' : 'Le mot de passe doit contenir au moins 8 caractères, une majuscule, un chiffre et un caractère spécial.'
        );
    };

    const handleConfirmPasswordChange = (event) => {
        const value = event.target.value;
        setConfirmPassword(value);

        if (password && value !== password) {
            setPasswordError('Les mots de passe ne correspondent pas.');
        } else {
            setPasswordError('');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Si la case "Terms and Conditions" n'est pas cochée, afficher une erreur
        if (!acceptTerms) {
            setTermsError(true); // Active l'erreur pour la checkbox
            setFormError('Veuillez accepter les termes et conditions.');
            return;
        }

        // Validation générale du formulaire
        if (passwordError || password !== confirmPassword || !firstname || !lastname || !email || !birthDate) {
            setFormError('Veuillez vérifier les erreurs dans le formulaire.');
            setAlertSeverity('error');
            return;
        }

        setIsLoading(true);
        const formattedBirthDate = format(birthDate, 'yyyy-MM-dd');

        const userData = {
            firstname,
            lastname,
            gender,
            birthday: formattedBirthDate,
            termsAccepted: acceptTerms,
            email,
            password,
        };

        try {
            const response = await api.post('register', userData);

            if (response.data.success) {
                setSuccessMessage(
                    'Inscription réussie ! Un email de confirmation a été envoyé à votre adresse. Vous serez redirigé vers la page de connexion dans 5 secondes.'
                );
                setAlertSeverity('success');
                setFormError('');

                setTimeout(() => {
                    navigate('/login');
                }, 5000);
            } else {
                setFormError('Une erreur inattendue est survenue.');
                setAlertSeverity('error');
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.errors
                ? Object.values(error.response.data.errors).flat().join(', ')
                : 'Une erreur est survenue lors de l\'inscription. Veuillez réessayer.';
            setFormError(errorMessage);
            setAlertSeverity('error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleTermsChange = (event) => {
        setAcceptTerms(event.target.checked);
        setTermsError(!event.target.checked); // Supprimer l'erreur si la case est cochée
    };

    const isSubmitDisabled = () => {
        return (
            !!passwordError ||
            !password ||
            !confirmPassword ||
            password !== confirmPassword ||
            !acceptTerms ||
            !firstname ||
            !lastname ||
            !email ||
            !birthDate
        );
    };

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                <Grid2 container component="main" sx={{ height: '100vh' }}>
                    <CssBaseline />
                    <Grid2
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mx: 'auto',
                            maxWidth: '500px',
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100%',
                                p: 4,
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'transparent', width: 56, height: 56 }}>
                                <img
                                    src="https://www.i-kiddy.com/images/i-kiddy-logo.png"
                                    alt="Logo"
                                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Register
                            </Typography>

                            {successMessage && (
                                <Alert variant="filled" severity="success" sx={{ width: '100%' }}>
                                    {successMessage}
                                </Alert>
                            )}

                            {formError && (
                                <Alert variant="filled" severity={alertSeverity} sx={{ width: '100%', mt: 1 }}>
                                    {formError}
                                </Alert>
                            )}

                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
                                <Grid2 container spacing={2}>
                                    <Grid2 xs={12} sm={6} sx={{ width: '48%' }}>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            name="firstName"
                                            autoComplete="First Name"
                                            autoFocus
                                            size="small"
                                            value={firstname}
                                            onChange={(e) => setFirstname(e.target.value)}
                                        />
                                    </Grid2>
                                    <Grid2 xs={12} sm={6} sx={{ width: '48%' }}>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="lastName"
                                            label="Last Name"
                                            name="lastName"
                                            autoComplete="Last Name"
                                            size="small"
                                            value={lastname}
                                            onChange={(e) => setLastname(e.target.value)}
                                        />
                                    </Grid2>
                                </Grid2>

                                <FormControl component="fieldset" sx={{ mt: 2 }}>
                                    <FormLabel component="legend">Gender</FormLabel>
                                    <RadioGroup row name="gender" value={gender} onChange={(e) => setGender(e.target.value)}>
                                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                                        <FormControlLabel value="other" control={<Radio />} label="Other" />
                                    </RadioGroup>
                                </FormControl>

                                <DatePicker
                                    label="Date of Birth"
                                    value={birthDate}
                                    onChange={(newValue) => setBirthDate(newValue)}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            size: 'small',
                                            margin: 'normal',
                                        },
                                    }}
                                />

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    size="small"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    size="small"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    error={!!passwordError}
                                    helperText={passwordError}
                                />

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type="password"
                                    id="confirmPassword"
                                    size="small"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    error={!!passwordError}
                                    helperText={passwordError}
                                />

                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={acceptTerms}
                                                onChange={handleTermsChange}
                                                color={termsError ? 'error' : 'primary'}
                                            />
                                        }
                                        label={
                                            <Typography sx={{ color: !acceptTerms ? 'red' : 'inherit' }}>
                                                I agree to the{' '}
                                                <Link href="/conditions" target="_blank" sx={{ textDecoration: 'none' }}>
                                                    Terms and Conditions
                                                </Link>
                                            </Typography>
                                        }
                                    />
                                    {!acceptTerms && (
                                        <Typography variant="caption" sx={{ color: 'red', ml: 2 }}>
                                            * Obligatoire
                                        </Typography>
                                    )}
                                </Box>

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={isSubmitDisabled() || isLoading}
                                >
                                    {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Register'}
                                </Button>

                                <Grid2 container justifyContent="flex-end">
                                    <Grid2>
                                        <Link href="/login" variant="body2" sx={{ textDecoration: 'none' }}>
                                            Already have an account? Sign in
                                        </Link>
                                    </Grid2>
                                </Grid2>
                            </Box>
                        </Box>
                    </Grid2>
                </Grid2>
            </LocalizationProvider>
        </ThemeProvider>
    );
}