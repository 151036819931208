import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Avatar, Box, Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link, useNavigate } from 'react-router-dom'; // Utilisé pour la navigation
import Logout from '../../functions/Logout';

export default function NavBar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

  // Simuler le nom d'utilisateur
  const userName = user.firstname + ' ' + user.lastname;
  const navigate = useNavigate(); // Hook pour la navigation

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const directionPath = (path) => {
    navigate('/home?page='+path); // Redirige vers la page de création du portail
    handleMenuClose(); // Ferme le menu
  };

  const { handleLogout } = Logout(); // Récupérer la fonction handleLogout

  return (
    <AppBar position="fixed" sx={{ backgroundColor: 'white', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)' }}>
      <Toolbar variant="dense" sx={{ display: 'flex', justifyContent: 'space-between', minHeight: '50px' }}>
        
        {/* Partie gauche - Logo et liens */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Logo */}
          <IconButton edge="start" color="inherit" sx={{ mr: 2 }}>
            <img src="https://www.i-kiddy.com/images/i-kiddy-logo.png" alt="Logo" style={{ height: '40px' }} />
          </IconButton>

          {/* Liens de navigation */}
          <Button color="inherit" component={Link} to="/home" sx={{ color: 'black' }}>Home</Button>
          <Button color="inherit" component={Link} to="/about" sx={{ color: 'black' }}>About</Button>
          <Button color="inherit" component={Link} to="/services" sx={{ color: 'black' }}>Services</Button>
        </Box>

        {/* Partie droite - Avatar, nom utilisateur et menu déroulant */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton edge="end">
            <Avatar alt={userName} src="/static/images/avatar/1.jpg" />
          </IconButton>
          <Typography variant="body1" sx={{ mx: 3, color: 'black' }}>
            {userName}
          </Typography>
          <IconButton onClick={handleMenuOpen}>
            <ArrowDropDownIcon sx={{ color: 'black' }} />
          </IconButton>

          {/* Menu déroulant */}
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }} // Modifier la position d'origine
            open={isMenuOpen}
            onClose={handleMenuClose}
            sx={{ mt: 5.1 }} // Ajoute une marge supérieure pour le décalage
          >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={()=>directionPath('createPortalForm')}>Nouveau portail</MenuItem> {/* Redirection vers la création du portail */}
            <MenuItem onClick={() => { handleLogout(); handleMenuClose(); }}>Logout</MenuItem> {/* Utilisation de la fonction handleLogout */}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}