import React from 'react';
import { Typography, Link, Box } from '@mui/material';

export default function Copyright(props) {
    return (
        <Box {...props}> {/* On passe les props, notamment 'sx', au composant Box */}
            <Typography variant="body2" color="text.secondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://yourwebsite.com/" sx={{ textDecoration: 'none' }}>
                    Your Website
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </Box>
    );
}