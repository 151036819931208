import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

const VerifyEmail = () => {
    const [searchParams] = useSearchParams(); // Pour récupérer le token depuis l'URL
    const [status, setStatus] = useState('loading'); // loading, success, error, expired
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const token = searchParams.get('token'); // Récupérer le token de l'URL

        if (!token) {
            setStatus('error');
            setMessage('Le token est manquant dans l\'URL.');
            return;
        }

        // Envoyer la requête au backend pour vérifier l'email
        const verifyEmail = async () => {
            try {
                const response = await axios.get(
                    `https://i-kiddy.com/public_html/index.php/api/verify-email?token=${token}`
                );

                if (response.data.success) {
                    setStatus('success');
                    setMessage('Votre email a été vérifié avec succès. Vous allez être redirigé(e) vers la page de connexion.');
                    // Redirection après 3 secondes
                    setTimeout(() => {
                        navigate('/login');
                    }, 3000);
                } else {
                    setStatus('error');
                    setMessage(response.data.message);
                }
            } catch (error) {
                if (error.response && error.response.data) {
                    const errorMessage = error.response.data.message || 'Une erreur est survenue.';
                    setMessage(errorMessage);

                    // Vérifier si le token est expiré
                    if (errorMessage.toLowerCase().includes('expiré')) {
                        setStatus('expired');
                    } else {
                        setStatus('error');
                    }
                } else {
                    setStatus('error');
                    setMessage('Une erreur est survenue lors de la vérification.');
                }
            }
        };

        verifyEmail();
    }, [searchParams, navigate]);

    return (
        <div style={{ marginTop: '50px', textAlign: 'center' }}>
            {status === 'loading' && <p>Vérification en cours...</p>}

            {status === 'success' && (
                <div>
                    <p style={{ color: 'green' }}>{message}</p>
                </div>
            )}

            {status === 'error' && (
                <div>
                    <p style={{ color: 'red' }}>{message}</p>
                </div>
            )}

            {status === 'expired' && (
                <div>
                    <p style={{ color: 'orange' }}>{message}</p>
                    <button
                        onClick={() => navigate('/resend-verification')}
                        style={{
                            backgroundColor: '#ff2d20',
                            color: '#fff',
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        Demander un nouveau lien
                    </button>
                </div>
            )}
        </div>
    );
};

export default VerifyEmail;