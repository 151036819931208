import React, { useState } from 'react';
import { TextField, Button, MenuItem, Box, Typography, InputLabel, FormControl, Select, Avatar } from '@mui/material';

export default function CreatePortalForm() {
  // États pour chaque champ du formulaire
  const [portalName, setPortalName] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [activityType, setActivityType] = useState('');
  const [bankAccount, setBankAccount] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [description, setDescription] = useState('');
  const [logo, setLogo] = useState(null); // Pour le téléchargement du logo

  const handleLogoUpload = (event) => {
    setLogo(event.target.files[0]); // Gérer l'upload du logo
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Gérer la soumission du formulaire et envoyer les données au back-end
    const formData = {
      portalName,
      contactPerson,
      activityType,
      bankAccount,
      email,
      phone,
      address,
      description,
      logo,
    };
    console.log('Form data submitted: ', formData);
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        mx: 'auto',
        mt: 5,
        p: 3,
        border: '1px solid #ccc',
        borderRadius: 2,
      }}
    >
      <Typography variant="h5" gutterBottom>
        Create Your Portal
      </Typography>
      <form onSubmit={handleSubmit}>
        {/* Nom du portail */}
        <TextField
          fullWidth
          label="Portal Name"
          value={portalName}
          onChange={(e) => setPortalName(e.target.value)}
          margin="normal"
          required
        />

        {/* Nom de la personne de contact */}
        <TextField
          fullWidth
          label="Contact Person"
          value={contactPerson}
          onChange={(e) => setContactPerson(e.target.value)}
          margin="normal"
          required
        />

        {/* Type d'activité */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Type of Activity</InputLabel>
          <Select
            value={activityType}
            onChange={(e) => setActivityType(e.target.value)}
            required
          >
            <MenuItem value="Stage">Stage</MenuItem>
            <MenuItem value="Parascolaire">Parascolaire</MenuItem>
            <MenuItem value="Excursion">Excursion</MenuItem>
            <MenuItem value="Evenement">Evenement</MenuItem>
            <MenuItem value="Aide scolaire">Aide scolaire</MenuItem>
            <MenuItem value="Plaine de jeu">Plaine de jeu</MenuItem>
          </Select>
        </FormControl>

        {/* Compte bancaire */}
        <TextField
          fullWidth
          label="Bank Account"
          value={bankAccount}
          onChange={(e) => setBankAccount(e.target.value)}
          margin="normal"
          required
        />

        {/* Email */}
        <TextField
          fullWidth
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          required
          type="email"
        />

        {/* Numéro de téléphone */}
        <TextField
          fullWidth
          label="Phone Number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          margin="normal"
          required
        />

        {/* Adresse postale */}
        <TextField
          fullWidth
          label="Postal Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          margin="normal"
          required
        />

        {/* Description */}
        <TextField
          fullWidth
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          margin="normal"
          multiline
          rows={4}
        />

        {/* Télécharger le logo */}
        <Box mt={2}>
          <input
            accept="image/*"
            type="file"
            id="logo-upload"
            style={{ display: 'none' }}
            onChange={handleLogoUpload}
          />
          <label htmlFor="logo-upload">
            <Button variant="contained" color="primary" component="span">
              Upload Logo
            </Button>
          </label>
          {logo && (
            <Box mt={2}>
              <Avatar src={URL.createObjectURL(logo)} alt="Portal Logo" />
            </Box>
          )}
        </Box>

        {/* Soumettre */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          fullWidth
        >
          Create Portal
        </Button>
      </form>
    </Box>
  );
}