import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import api from '../utils/api'; // Import de l'instance Axios configurée

const PrivatePage = () => {
    const [cars, setCars] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [newCar, setNewCar] = useState({
        id: null, // Ajout de l'ID pour identifier la voiture à modifier
        brand: '',
        color: '',
        top_speed: '',
        engine_type: '',
        horsepower: '',
        transmission: ''
    });

    useEffect(() => {
        let isMounted = true; // Variable de contrôle pour éviter de mettre à jour l'état après démontage
    
        const fetchCars = async () => {
            const accessToken = localStorage.getItem('access_token');
    
            if (!accessToken) {
                console.error("Pas de jeton d'accès disponible pour récupérer les voitures.");
                return;
            }
    
            try {
                const response = await api.get('cars', {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
    
                if (isMounted) { // Mettre à jour l'état seulement si le composant est toujours monté
                    setCars(response.data);
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des voitures :", error);
            } finally {
                if (isMounted) setLoading(false);
            }
        };
    
        fetchCars();
    
        return () => {
            isMounted = false; // Cleanup pour éviter les fuites de mémoire
        };
    }, []);

    

    const handleOpenModal = (car = {}) => {
        // Préparer le formulaire pour modifier ou ajouter une voiture
        setNewCar({
            id: car.id || null,
            brand: car.brand || '',
            color: car.color || '',
            top_speed: car.top_speed || '',
            engine_type: car.engine_type || '', // Assurez-vous que cette ligne récupère bien les valeurs
            horsepower: car.horsepower || '',
            transmission: car.transmission || '' // Assurez-vous que cette ligne récupère bien les valeurs
        });
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setNewCar({
            id: null,
            brand: '',
            color: '',
            top_speed: '',
            engine_type: '',
            horsepower: '',
            transmission: ''
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewCar({ ...newCar, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const accessToken = localStorage.getItem('access_token');

        if (!accessToken) {
            console.error("Pas de jeton d'accès disponible pour ajouter ou modifier une voiture.");
            return;
        }

        try {
            if (newCar.id) {
                // Mise à jour de la voiture existante
                await api.put(`cars/${newCar.id}`, newCar, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
            } else {
                // Ajout d'une nouvelle voiture
                await api.post('cars', newCar, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
            }

            // Rafraîchir la liste des voitures
            const response = await api.get('cars', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            setCars(response.data);
            handleCloseModal();
        } catch (error) {
            console.error("Erreur lors de l'ajout ou de la modification de la voiture :", error);
        }
    };

    const handleDelete = async (id) => {
        const accessToken = localStorage.getItem('access_token');

        if (!accessToken) {
            console.error("Pas de jeton d'accès disponible pour supprimer une voiture.");
            return;
        }

        try {
            await api.delete(`cars/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            const response = await api.get('cars', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            setCars(response.data);
        } catch (error) {
            console.error("Erreur lors de la suppression de la voiture :", error);
        }
    };

    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h4">Page Privée</Typography>
            <Typography variant="h6">
                {user ? `Bienvenue, ${user.firstname} ${user.lastname}!` : 'Bienvenue, utilisateur anonyme !'}
            </Typography>
            <Typography variant="body1">
                {user ? `Votre email: ${user.email}` : 'Email non disponible'}
            </Typography>
            <Typography variant="body1">
                {user ? `Votre date de naissance: ${user.birthday}` : 'Date de naissance non disponible'}
            </Typography>


            

            <Button 
                variant="contained" 
                color="primary" 
                sx={{ mt: 3 }} 
                onClick={() => handleOpenModal()}
            >
                Ajouter une Voiture
            </Button>

            <Box sx={{ mt: 4 }}>
                <Typography variant="h5">Liste des Voitures ({cars.length})</Typography>
                {loading ? (
                    <Typography variant="body1">Chargement...</Typography>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Marque</TableCell>
                                    <TableCell>Couleur</TableCell>
                                    <TableCell>Vitesse max</TableCell>
                                    <TableCell>Type de moteur</TableCell>
                                    <TableCell>Puissance</TableCell>
                                    <TableCell>Transmission</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cars.map((car) => (
                                    <TableRow key={car.id}>
                                        <TableCell>{car.brand}</TableCell>
                                        <TableCell>{car.color}</TableCell>
                                        <TableCell>{car.top_speed}</TableCell>
                                        <TableCell>{car.engine_type}</TableCell>
                                        <TableCell>{car.horsepower}</TableCell>
                                        <TableCell>{car.transmission}</TableCell>
                                        <TableCell>
                                            <Button 
                                                variant="outlined" 
                                                color="primary" 
                                                onClick={() => handleOpenModal(car)} // Ouvre le modal pour modifier
                                            >
                                                Modifier
                                            </Button>
                                            <Button 
                                                variant="outlined" 
                                                color="secondary" 
                                                onClick={() => handleDelete(car.id)}
                                                sx={{ ml: 1 }} // Ajoute un petit espace entre les boutons
                                            >
                                                Supprimer
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Box>

            <Modal open={openModal} onClose={handleCloseModal}>
                <Box 
                    sx={{ 
                        position: 'absolute', 
                        top: '50%', 
                        left: '50%', 
                        transform: 'translate(-50%, -50%)', 
                        width: '400px',
                        bgcolor: 'background.paper', 
                        boxShadow: 24, 
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <Typography variant="h6">{newCar.id ? "Modifier une Voiture" : "Ajouter une Voiture"}</Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField 
                            id="brand" // Ajout d'un id unique
                            label="Marque" 
                            name="brand" 
                            value={newCar.brand} 
                            onChange={handleChange} 
                            fullWidth 
                            required 
                            sx={{ mb: 2 }} 
                        />
                        <TextField 
                            id="color" // Ajout d'un id unique
                            label="Couleur" 
                            name="color" 
                            value={newCar.color} 
                            onChange={handleChange} 
                            fullWidth 
                            required 
                            sx={{ mb: 2 }} 
                        />
                        <TextField 
                            id="top_speed" // Ajout d'un id unique
                            label="Vitesse max" 
                            name="top_speed" 
                            value={newCar.top_speed} 
                            onChange={handleChange} 
                            fullWidth 
                            required 
                            type="number"
                            sx={{ mb: 2 }} 
                        />
                        <FormControl fullWidth sx={{ mb: 2 }} required>
                            <InputLabel id="engine_type_label">Type de moteur</InputLabel> 
                            <Select 
                                labelId="engine_type_label" // Ajout d'un label id pour le contrôle Select
                                id="engine_type" // Ajout d'un id unique
                                name="engine_type" 
                                value={newCar.engine_type} 
                                onChange={handleChange} 
                            >
                                <MenuItem value="Petrol">Petrol</MenuItem>
                                <MenuItem value="Diesel">Diesel</MenuItem>
                                <MenuItem value="Electric">Electric</MenuItem>
                                <MenuItem value="Hybrid">Hybrid</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField 
                            id="horsepower" // Ajout d'un id unique
                            label="Puissance" 
                            name="horsepower" 
                            value={newCar.horsepower} 
                            onChange={handleChange} 
                            fullWidth 
                            required 
                            type="number"
                            sx={{ mb: 2 }} 
                        />
                        <FormControl fullWidth sx={{ mb: 2 }} required>
                            <InputLabel id="transmission_label">Transmission</InputLabel>
                            <Select 
                                labelId="transmission_label" // Ajout d'un label id pour le contrôle Select
                                id="transmission" // Ajout d'un id unique
                                name="transmission" 
                                value={newCar.transmission} 
                                onChange={handleChange} 
                            >
                                <MenuItem value="Manual">Manual</MenuItem>
                                <MenuItem value="Automatic">Automatic</MenuItem>
                            </Select>
                        </FormControl>
                        <Button variant="contained" type="submit" sx={{ mr: 2 }}>
                            Sauvegarder
                        </Button>
                        <Button variant="outlined" onClick={handleCloseModal}>
                            Annuler
                        </Button>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
};

export default PrivatePage;