// Logout.jsx
import { useDispatch } from "react-redux";
import { constants } from '../auth/_constants/constants';
import api from '../utils/api'; // Import de l'instance Axios configurée
import Cookies from 'js-cookie';

const Logout = () => {
    const dispatch = useDispatch();

    const handleLogout = async () => {
        const accessToken = localStorage.getItem('access_token');

        if (!accessToken) {
            console.error("Pas de jeton d'accès disponible pour la déconnexion.");
            return;
        }

        try {
            await api.post('logout', {}, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            localStorage.removeItem('access_token');
            localStorage.removeItem('user');
            Cookies.remove('refresh_token');
            dispatch({ type: constants.LOGOUT });
            window.location.href = '/login';
        } catch (error) {
            console.error("Erreur lors de la déconnexion :", error);
        }
    };

    return { handleLogout }; // Renvoie la fonction handleLogout
};

export default Logout;